import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Hint2 = () => (
  <Layout>
    <Header /> 

    <header className="masthead2">
    <br></br>
    <br></br>
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Hint #2</h1>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            The tragedy that struck 
            <br></br>
            was not started with matches.
            <br></br>
            <br></br>
            Go to the place that
            <br></br>
            rose from the ashes.
            <br></br>
            <br></br>             
            <br></br>      
          </h2>
          <h3 className="roll-in-bottom mx-auto">
          Distance: 100' North</h3>

          <br></br>
          <br></br>
          <a href="/clue2" className="btn btn-primary">
              Back to Clue
            </a>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          {/* <Scroll type="id" element="about">  
            <a href="#about" className="btn btn-primary">
              Hint
            </a>
          </Scroll> */}
        </div>
      </div>
    </header>

    {/* <Clues />  

    <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Hint2;
